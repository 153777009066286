/* eslint-disable import/no-cycle */
import { pageContent } from '@/constant/contentfulConstants';
import getContentful from '@/src/lib/contentful';

export const getShopPageData = ({ ctx, shopId }) => {
  const { query, asPath, isServer } = ctx;

  return async (dispatch) => {
    dispatch({
      type: 'SET_SHOP_PAGE_DATA',
      payload: {
        shopPageLoader: true,
        show404Page: false,
      },
    });

    const pageSlug = shopId != null ? shopId : query.shop.length > 1 ? query.shop[1] : query.shop[0];

    // opecoDataWCMS;
    const contentfulEntries = await getContentful({
      type: pageContent.type,
      include: 7,
      field: pageContent.key,
      value: pageSlug,
    });

    const show404Page = contentfulEntries?.items?.length <= 0;

    let floorEntires = [];

    if (!show404Page && contentfulEntries?.items?.[0]?.fields?.floor?.length) {
      floorEntires = contentfulEntries?.items?.[0]?.fields?.floor;
    }

    const wcmsEntry = contentfulEntries?.items[0]?.fields || {};

    dispatch({
      type: 'SET_SHOP_PAGE_DATA',
      payload: {
        shopPageLoader: show404Page,
        show404Page,
        shopPage: {
          meta: {
            title: wcmsEntry.metaTitle || 'Decathlon',
            description: wcmsEntry.metaDescription || 'Decathlon',
            pageDescription: wcmsEntry.pageDescription,
          },
          entries: floorEntires || [],
        },
      },
    });

    return { entries: contentfulEntries, asPath, isServer };
  };
};

const shopPage = {
  getShopPageData,
};

export default shopPage;
